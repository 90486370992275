<template>
  <page-layout ref="layout" @refresh="refresh">
    <template #breadcrumbs="{ }">
      <b-breadcrumb-item :text="`Reports - ${$store.state.settings.app.current.title}`" />
      <b-breadcrumb-item text="Chaperone Roster" active/>
    </template>

    <template #dropdown-options>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{state}">
			<span class="d-none d-print-block p-50 font-weight-bold">{{ selectedEnsemble.name }}</span>

			<table-layout ref="table-layout"
										:loading="table.loading"
										:fields="table.fields"
										:items="table.items"
										:sorting="table.sorting"
										:filters="table.filters" :filters-options="{ visible: true, collapsed: false }"
                    :export-file-name="exportFileName"
                    :export-exclude-fields="[
                        'id',
                        'selectionEnsembleId',
                        'application.id',
                        'application.instrument.id',
                        'application.teacher.username',
                        'iop', 'hasDetails'
                    ]">

				<template #overlay>
					<overlay-loading :items="[
            { state: table.loading, desc: 'Loading Selections', loaded: table.loaded},
            { state: state.loading, desc: 'Rendering Template'},
          ]" />
        </template>

				<template #filters>
					<b-row>
						<b-col>
							<b-form-group label="Ensemble" label-for="ensemble-input">
								<template #label>
									Ensemble (<span class="text-danger">required</span>)
								</template>
								<v-select id="ensemble-input"
													v-model="table.filters.ensemble.value"
													:options="options.ensembles.items"
													:loading="options.ensembles.loading"
													:reduce="ensemble => ensemble.id"
													label="name"
													:select-on-tab="true"
													:clearable="false"
													:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
													class="w-100 d-print-none"
													@input="getSelections"/>
							</b-form-group>
						</b-col>
            <b-col>
              <b-form-group label="Instrument" label-for="instrument-input">
                <v-select id="instrument-input"
                          v-model="table.filters.instrument.value"
                          :options="options.instruments.items"
                          :loading="options.instruments.loading"
                          :reduce="instrument => instrument.id"
                          label="name"
                          :select-on-tab="true"
                          :clearable="true"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          class="w-100 d-print-none"/>
              </b-form-group>
            </b-col>
					</b-row>
				</template>


        <template #cell(student)="{data}">
          <b-media vertical-align="center" no-body class="d-print-none">
            <b-media-aside>
              <b-avatar variant="primary" size="2.5em"
                        badge-variant="primary" badge-offset="-2px">
                <font-awesome-icon icon="fas fa-graduation-cap"/>
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              {{ data.item.application.student.name.first }} {{ data.item.application.student.name.last }}
            </b-media-body>
          </b-media>
        </template>

        <!-- Instrument -->
        <template #cell(application.instrument.name)="{data}">
          <span v-if="hasValue(data, 'item.application.instrument')">
            {{ data.item.application.instrument.name }}
          </span>
          <span v-else class="text-danger">No Instrument</span>
        </template>

        <!-- School -->
        <template #cell(application.student.school.name.legal)="{data}">
          <span v-if="hasValue(data, 'item.application.student.school.name')">
            {{ data.item.application.student.school.name.legal }}
          </span>
          <span v-else class="text-danger">No School</span>
        </template>

        <!-- Teacher Name -->
        <template #cell(teacher)="{data}">
          <span v-if="hasValue(data, 'item.application.teacher.name')">
            {{ data.item.application.teacher.name.first }} {{ data.item.application.teacher.name.last }}
          </span>
          <span v-else class="text-danger">No Teacher</span>
        </template>

        <!-- Zone -->
        <template #cell(application.student.school.zone.name)="{data}">
          <span v-if="hasValue(data, 'item.application.student.school.zone.name')">
            {{ data.item.application.student.school.zone.name }}
          </span>
          <span v-else class="text-danger">No Zone</span>
        </template>

        <!-- County -->
        <template #cell(application.student.school.address.county)="{data}">
          <span v-if="hasValue(data, 'item.application.student.school')">
            {{ data.item.application.student.school.address.county }}
          </span>
          <span v-else class="text-danger">No County</span>
        </template>

        <!-- Accepted -->
				<template #cell(accepted)="{data}">
					<b-badge :variant="getAcceptedVariant(data.item.accepted)">
						{{ getAcceptedText(data.item.accepted) }}
					</b-badge>
				</template>
      </table-layout>
    </template>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import {API, Auth, graphqlOperation} from 'aws-amplify';
import {getUser, listEnsembles, listSelections, listZones} from './queries/chaperone-roster';
import vSelect from 'vue-select';
import TableLayout from '@/components/TableLayout.vue';
import OverlayLoading from '@/components/OverlayLoading.vue';
import acceptanceMixin from '@/mixins/acceptance.mixin';
import TableRowOptions from '@/components/TableRowOptions.vue';
import lodashMixin from '@/mixins/lodash.mixin';
import settingsMixin from '@/mixins/settings.mixin';

export default {
  name: 'EnsembleRoster',
  components: {
    TableRowOptions,
    OverlayLoading,
    TableLayout,
    PageLayout,
    vSelect
  },
  mixins: [ acceptanceMixin, lodashMixin, settingsMixin ],
  data() {
    return {
      user: this.$store.state.user,
      table: {
        fields: [
          {
            key: 'student',
            subKeys: ['application.student.name.first', 'application.student.name.last'],
            label: 'Student',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.instrument.name',
            label: 'Instrument',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'part',
            label: 'Part',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.bus.name',
            label: 'Bus',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.phone.number',
            label: 'Phone',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.email.address',
            label: 'Email',
            sortable: false,
            filterable: false,
            visible: false,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.school.name.legal',
            label: 'School',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.school.district.name.legal',
            label: 'District',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.school.zone.name',
            label: 'Zone',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'application.student.school.address.county',
            label: 'County',
            sortable: false,
            filterable: true,
            visible: true,
            tdClass: 'align-middle'
          },
          {
            key: 'teacher',
            subKeys: ['application.teacher.name.first', 'application.teacher.name.last'],
            label: 'Teacher',
            sortable: false,
            filterable: true,
            visible: false,
            tdClass: 'align-middle'
          },
          {
              key: 'accepted',
              label: 'Accepted',
              sortable: false,
              filterable: false,
              visible: true,
              tdClass: 'align-middle'
          },
        ],
				sorting: {
					by: 'id',
					comparator: (a, b) => this.compareByInstrumentOrderAndPart(a, b),
					comparatorKey: 'iop',
					comparators: [
						{ key: 'iop', text: 'Instrument Order & Part', comparator: (a, b) => this.compareByInstrumentOrderAndPart(a, b) },
					]
				},
        search: {
          type: '\'',
          value: '',
          options: {
            types: [
              { text: 'Fuzzy', value: '' },
              { text: 'Exact', value: '=' },
              { text: 'Includes', value: '\'' },
              { text: 'Starts With', value: '^' },
            ]
          },
        },
        filters: {
          ensemble: { key: 'selectionEnsembleId', value: null },
          instrument: { key: 'application.instrument.id', value: null },
        },
        items: [],
        loading: false
      },
      users: [],
      usersMap: {},
      options: {
        ensembles: {
          items: [],
          loading: true
        },
        instruments: {
          items: [],
          loading: false
        },
        zones: {
          items: [],
          loading: true
        }
      },
    }
  },
  computed: {
    selectedEnsemble() {
      const selected = this.options.ensembles.items.find(item => item.id === this.table.filters.ensemble.value)
      if(selected) return selected
      return { name: '', slug: '' }
    },
    exportFileName() {
      return `chaperone-roster-${this.selectedEnsemble.slug}-${new Date().toISOString().slice(0, 10)}`
    },
  },
  async mounted() {
    await this.getCurrentUser()
    this.$refs.layout.state.loading = false
    await Promise.all([
      await this.listEnsembles(),
      await this.listZones()
    ]);
    if(this.$route.query.ensemble) {
      this.table.filters.ensemble.value = this.$route.query.ensemble
      await this.getSelections(this.$route.query.ensemble)
    }
  },
  methods: {
    async getCurrentUser() {
      /** Get Current User from Store **/
      const cognitoUser = await Auth.currentAuthenticatedUser()

      /** Get User from AppSync **/
      const response = await API.graphql(graphqlOperation(getUser, { id: cognitoUser.attributes['custom:user_id'] }));
      this.user = response.data.getUser
      this.user.groups = cognitoUser.signInUserSession.accessToken.payload['cognito:groups']
    },
    async listEnsembles() {
      const response = await API.graphql(graphqlOperation(listEnsembles));
      const items = response.data.listEnsembles.items.sort((a, b) => a.name.localeCompare(b.name));

      if(this.user.groups.includes('NYSSMA_Chair')) {
        this.options.ensembles.items = items.filter(item => this.user.ensembleIds.includes(item.id))
      }
      else {
        this.options.ensembles.items = items
      }
      this.options.ensembles.loading = false
    },
    async listZones() {
      const response = await API.graphql(graphqlOperation(listZones));
      this.options.zones.items = response.data.listZones?.items?.sort((a, b) => a.name.localeCompare(b.name)) || []
      this.options.zones.loading = false
    },
    async getSelections(ensembleId) {
      if(!this.table.items.some(item => item.selectionEnsembleId === ensembleId)) {
        if(this.$route.query?.ensemble !== ensembleId) {
          await this.$router.push({query: {ensemble: ensembleId}})
          this.table.filters.instrument.value = null
          this.options.instruments.loading = true
        }
        this.table.loading = true
        await this.listSelections(ensembleId)
      }
    },
    async listSelections(ensembleId, nextToken, pagedSelections) {
      const selections = pagedSelections || []
      const input = {
        filter: {
          selectionEnsembleId: { eq: ensembleId },
          accepted: { ne: false },
          createdAt: {
            between: [
              this.settingsStore.app.current.year.start, this.settingsStore.app.current.year.end
            ]
          },
        },
        limit: 500,
        nextToken: nextToken
      }

      const response = await API.graphql(graphqlOperation(listSelections, input));
      selections.push(...response.data.listSelections.items);

      if(response.data.listSelections.nextToken) {
        await this.listSelections(ensembleId, response.data.listSelections.nextToken, selections)
      }
      else {
        const instrumentsMap = new Map();
        const instrumentsArray = [];

        selections.forEach(selection => {
          selection.hasDetails = false

          const {instrument} = selection.application;
          if (!instrumentsMap.has(instrument.id)) {
            instrumentsMap.set(instrument.id, instrument);
            instrumentsArray.push(instrument);
          }

          selection.iop = this.compareByInstrumentOrderAndPart(selection, selection)
        })

        this.options.instruments.items = instrumentsArray.sort((a, b) => (a?.order - b?.order) || a?.name?.localeCompare(b?.name))
        if(!this.options.instruments.items.some(item => item.id === this.table.filters.instrument.value)) {
          this.table.filters.instrument.value = null
        }
        this.options.instruments.loading = false


        this.table.items = selections
        this.table.loading = false
      }
    },

		compareByInstrumentOrderAndPart(a, b) {
			return a.application.instrument.order - b.application.instrument.order
					|| a.part?.localeCompare(b.part, undefined, { numeric: true, sensitivity: 'base' })
					|| a.application.student.name.first.localeCompare(b.application.student.name.first)
					|| a.application.student.name.last.localeCompare(b.application.student.name.last)
		},

    async refresh() {
      this.table.loading = true
      this.options.instruments.loading = true
      await this.listSelections(this.table.filters.ensemble.value)
    },
    print() {
        this.$nextTick(() => {
            window.print()
        })
    }
  }
}
</script>

<style scoped>
  @media print {
    table > tbody > tr > td {
        font-size: 12px!important;
    }
    .print-title
    {
      display: block !important;
    }
    .badge {
        border-width: 0;
        padding: 0;
        background: transparent;
        color: inherit;
        font-weight: 400;
    }
}
</style>
