// eslint-disable-next-line import/prefer-default-export
export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            name {
                first
                last
            }
            ensembleIds
            instrumentIds
        }
    }
`;
export const listSelections = /* GraphQL */ `
    query ListSelections(
        $id: ID
        $filter: ModelSelectionFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSelections(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                part
                ensemble {
                    name
                }
                selectionEnsembleId
                accepted
                application {
                    id
                    instrument {
                        id
                        name
                        order
                    }
#                    festival {
#                        zone {
#                            name
#                        }
#                    }
                    teacher {
                        username
                        name {
                            first
                            last
                        }
                    }
                    student {
                        name {
                            first
                            last
                        }
                        phone {
                            number
                        }
                        email {
                            address
                        }
                        school {
                            name {
                                legal
                            }
                            address {
                                county
                            }
                            district {
                                name {
                                    legal
                                }
                            }
                            zone {
                                name
                            }
                        }
                        bus {
                            name
                        }
                    }
                }
            }
            nextToken
        }
    }
`;

export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                slug
            }
            nextToken
        }
    }
`;

export const listZones = /* GraphQL */ `
    query ListZones(
        $id: ID
        $filter: ModelZoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listZones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                counties
            }
            nextToken
        }
    }
`;
