var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{ref:"layout",on:{"refresh":_vm.refresh},scopedSlots:_vm._u([{key:"breadcrumbs",fn:function(ref){return [_c('b-breadcrumb-item',{attrs:{"text":("Reports - " + (_vm.$store.state.settings.app.current.title))}}),_c('b-breadcrumb-item',{attrs:{"text":"Chaperone Roster","active":""}})]}},{key:"dropdown-options",fn:function(){return [_c('b-dropdown-item',{on:{"click":_vm.refresh}},[_c('feather-icon',{attrs:{"icon":"RotateCwIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Refresh")])],1)]},proxy:true},{key:"content",fn:function(ref){
var state = ref.state;
return [_c('span',{staticClass:"d-none d-print-block p-50 font-weight-bold"},[_vm._v(_vm._s(_vm.selectedEnsemble.name))]),_c('table-layout',{ref:"table-layout",attrs:{"loading":_vm.table.loading,"fields":_vm.table.fields,"items":_vm.table.items,"sorting":_vm.table.sorting,"filters":_vm.table.filters,"filters-options":{ visible: true, collapsed: false },"export-file-name":_vm.exportFileName,"export-exclude-fields":[
                        'id',
                        'selectionEnsembleId',
                        'application.id',
                        'application.instrument.id',
                        'application.teacher.username',
                        'iop', 'hasDetails'
                    ]},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('overlay-loading',{attrs:{"items":[
            { state: _vm.table.loading, desc: 'Loading Selections', loaded: _vm.table.loaded},
            { state: state.loading, desc: 'Rendering Template'} ]}})]},proxy:true},{key:"filters",fn:function(){return [_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Ensemble","label-for":"ensemble-input"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Ensemble ("),_c('span',{staticClass:"text-danger"},[_vm._v("required")]),_vm._v(") ")]},proxy:true}],null,true)},[_c('v-select',{staticClass:"w-100 d-print-none",attrs:{"id":"ensemble-input","options":_vm.options.ensembles.items,"loading":_vm.options.ensembles.loading,"reduce":function (ensemble) { return ensemble.id; },"label":"name","select-on-tab":true,"clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},on:{"input":_vm.getSelections},model:{value:(_vm.table.filters.ensemble.value),callback:function ($$v) {_vm.$set(_vm.table.filters.ensemble, "value", $$v)},expression:"table.filters.ensemble.value"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Instrument","label-for":"instrument-input"}},[_c('v-select',{staticClass:"w-100 d-print-none",attrs:{"id":"instrument-input","options":_vm.options.instruments.items,"loading":_vm.options.instruments.loading,"reduce":function (instrument) { return instrument.id; },"label":"name","select-on-tab":true,"clearable":true,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.table.filters.instrument.value),callback:function ($$v) {_vm.$set(_vm.table.filters.instrument, "value", $$v)},expression:"table.filters.instrument.value"}})],1)],1)],1)]},proxy:true},{key:"cell(student)",fn:function(ref){
          var data = ref.data;
return [_c('b-media',{staticClass:"d-print-none",attrs:{"vertical-align":"center","no-body":""}},[_c('b-media-aside',[_c('b-avatar',{attrs:{"variant":"primary","size":"2.5em","badge-variant":"primary","badge-offset":"-2px"}},[_c('font-awesome-icon',{attrs:{"icon":"fas fa-graduation-cap"}})],1)],1),_c('b-media-body',{staticClass:"align-self-center"},[_vm._v(" "+_vm._s(data.item.application.student.name.first)+" "+_vm._s(data.item.application.student.name.last)+" ")])],1)]}},{key:"cell(application.instrument.name)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.instrument'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.instrument.name)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Instrument")])]}},{key:"cell(application.student.school.name.legal)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.student.school.name'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.student.school.name.legal)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No School")])]}},{key:"cell(teacher)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.teacher.name'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.teacher.name.first)+" "+_vm._s(data.item.application.teacher.name.last)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Teacher")])]}},{key:"cell(application.student.school.zone.name)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.student.school.zone.name'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.student.school.zone.name)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No Zone")])]}},{key:"cell(application.student.school.address.county)",fn:function(ref){
          var data = ref.data;
return [(_vm.hasValue(data, 'item.application.student.school'))?_c('span',[_vm._v(" "+_vm._s(data.item.application.student.school.address.county)+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("No County")])]}},{key:"cell(accepted)",fn:function(ref){
          var data = ref.data;
return [_c('b-badge',{attrs:{"variant":_vm.getAcceptedVariant(data.item.accepted)}},[_vm._v(" "+_vm._s(_vm.getAcceptedText(data.item.accepted))+" ")])]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }